
import React, { useState, useEffect, useRef } from "react";
import {
  SearchContainer,
  StyledInput,
  Layout,
  Container,
  SearchButton,
} from "../search/search.module";
import { client } from "../../client";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import debounce from "lodash/debounce"; // Import debounce

import { AutoComplete } from "./AutoComplete";
import { ProductList } from "./ProductList";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const GET_AUTO_COMPLETE_V2 = gql`
query getAutoCompleteResultV2($search_keyword:String) {
  getAutoCompleteResultV2(search_keyword:$search_keyword) {
    keyword
    input
    type
    img
    ref_code
    score
  }
}
`;
const GET_SEARCH_RESULT = gql`
  query getProductListByBrandNameKr($search_keyword: String) {
    getProductListByBrandNameKr(search_keyword: $search_keyword) {
      ref_code
      cnt
      data {
        duty_free_site
        brand
        name
        price_member
        price_member_eng
        img_resized_url
        img_url
        category_id
      }
    }
  }
`;
const GET_SEARCH_RESULT_RANDOM = gql`
  query getProductListByRandomKeyword($search_keyword: String) {
    getProductListByRandomKeyword(search_keyword: $search_keyword) {
      ref_code
      cnt
      fts_score
      score
      data {
        duty_free_site
        brand
        name
        price_normal_eng
        price_member_eng
        img_resized_url
        img_url
        category_id
      }
    }
  }
`;

export const ProductSearchInput = ({
  keyword,
  setShowDefaultPage,
  minPrice,
  maxPrice,
  categoryId,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (keyword) {
      setInputValue(keyword); // Set the input value to the keyword from the URL
      handleSearch(keyword); // Perform the search
    }
    inputRef.current.focus();
  }, [keyword]);

  const inputRef = useRef(null); // Create a ref for the input element

  const [inputValue, setInputValue] = useState("");
  const [keywordList, setKeywordList] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [recentKeyword, setRecentKeyword] = useState();
  const [loading, setLoading] = useState(false);

  const [showAutoComplete, setShowAutoComplete] = useState(true);

  // Define a debounced version of the onValueChange function
  const debouncedOnValueChange = debounce((value) => {
    // Call the API to fetch autocomplete results
    if (value.length != "") {
      client
        .query({
          query: GET_AUTO_COMPLETE_V2,
          variables: {
            search_keyword: value,
          },
          // fetchPolicy: 'no-cache',
        })
        .then((result) => {
          setKeywordList(result.data?.getAutoCompleteResultV2 || []);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, 300); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

  useEffect(() => {
    debouncedOnValueChange(inputValue);
  }, [inputValue, debouncedOnValueChange]);

  const onValueChange = (value) => {
    setShowAutoComplete(true);
    setInputValue(value);
    // debouncedOnValueChange(value); // Call the debounced function

    if (value.length != "") {
      setShowDefaultPage(false);
    } else {
      setShowDefaultPage(true);
    }
  };

  useEffect(() => {
    if (recentKeyword != null) {
      let storedList = localStorage.getItem("recentKeyword");
      let retrievedObject = [];
      if (storedList != null) {
        retrievedObject = JSON.parse(storedList);
      }
      window.localStorage.setItem(
        "recentKeyword",
        JSON.stringify([recentKeyword, ...retrievedObject.slice(0, 7)])
      );
    }
  }, [recentKeyword]);

  const handleItemClick = (keyword) => {
    console.log("keyword선택...", keyword);
    setShowAutoComplete(false);
    setSearchKeyword(keyword);
    // alert('상픔 바꾸기')
    // Trigger the query or any other action here with the selected keyword
    // Example: You can trigger a query or set the search results based on the selected keyword
    // Add the searchKeyword parameter to the URL

    //
    client
      .query({
        query: GET_SEARCH_RESULT,
        variables: {
          search_keyword: keyword,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log("가져오기 성공", result.data);
        setSearchResult(result.data?.getProductListByBrandNameKr || []);
      })
      .catch((err) => {
        console.log("가져오기 실패");
        console.log(err);
      });
  };

  const handleProductItemClick = (ref_code) => {
    navigate(`/product/${ref_code}`);
  };

  const handleSearchBtnClick = (keyword) => {
    console.log("keyword선택...!", keyword);

    // keyword
    const params = new URLSearchParams(window.location.search);
    params.set("keyword", keyword);
    const queryString = params.toString();
    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      queryString;
    // window.history.pushState({ path: newUrl }, "", newUrl);
    navigate(`/search?${params.toString()}`, { replace: false });

    // // alert('상픔 바꾸기')
    // // Trigger the query or any other action here with the selected keyword
    // // Example: You can trigger a query or set the search results based on the selected keyword
  };

  const handleSearch = (keyword) => {
    try {
      setShowAutoComplete(false);
      setSearchKeyword(keyword);
      setRecentKeyword(keyword);
      setLoading(true);

      client
        .query({
          query: GET_SEARCH_RESULT_RANDOM,
          variables: {
            search_keyword: keyword,
          },
          // fetchPolicy: 'cache-first',
          fetchPolicy: "no-cache",
          // fetchPolicy:  'cache-and-network'
        })
        .then((result) => {
          console.log("가져오기 성공", result.data);
          setLoading(false);
          setSearchResult(result.data?.getProductListByRandomKeyword || []);
        })
        .catch((err) => {
          console.log("가져오기 실패");
          console.log(err);
          setLoading(false);
          // setSearchKeyword(keyword);
        });
    } catch (error) {
      console.log('handleSearch => error:', error)
    }
  }

  return (
    <Layout>
      <Container>
        <SearchContainer>
          <StyledInput
            ref={inputRef} // Assign the ref to the input element
            type="text"
            placeholder="상품을 검색해주세요"
            value={inputValue}
            onChange={(e) => {
              onValueChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchBtnClick(inputValue);
                setSearchKeyword(keyword);
              }
            }}
          />
          <SearchButton
            onClick={() => handleSearchBtnClick(inputValue)}
            url={
              "https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_search%402x.png"
            }
          ></SearchButton>
        </SearchContainer>
        {inputValue != "" && showAutoComplete ? (
          <AutoComplete
            keywordList={keywordList}
            handleItemClick={handleItemClick}
            handleProductItemClick={handleProductItemClick}
          ></AutoComplete>
        ) : null}
        {!showAutoComplete ? (
          loading ? (
            <Box
              sx={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ProductList
              keyword={inputValue}
              searchResult={searchResult}
              minPrice={minPrice}
              maxPrice={maxPrice}
              categoryId={categoryId}
            ></ProductList>
          )
        ) : null}
      </Container>
    </Layout>
  );
};

