import React, { useState, useEffect } from "react";
import {
  Layout,
  Container,
  TitleContainer,
  CategoryChip,
  ProductListContainer,
  ProductItemContainer,
  ProductItemImageContainer,
  Underline,
} from "../../../style/Home/RecommendSection.module.js";
import { client } from "../../../client.js";
import { GET_RECO_PRODUCT } from "../gql/queries.js";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BottomAd from "../../ad-banner/BottomAd.js";

const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  grid-gap: 17px; /* Adjust the gap between items as needed */
  padding-top: 24px;
  padding-bottom: 24px;
  /* Add any additional styles for the container here */
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    /* Add styles for the image here */
    max-width: 80%; /* Ensure the image doesn't exceed the container width */
    height: auto;
    border-radius: 50%; /* 원 모양 */
    background-color: #f0f0f0; /* 회색 배경 */
  }

  p {
    /* Add styles for the text here */
    margin-top: 8px; /* Adjust the spacing between image and text */
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
  }
`;

export const MainCategorySection = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") == null
      ? "향수/바디/헤어"
      : localStorage.getItem("selectedCategory")
  );
  const [itemList, setItemList] = useState([]);

  const navigate = useNavigate();

  const handleClickButton = (categoryName, id) => {
    const propsToPass = {
      categoryName: categoryName,
      id: id,
      // Add any other props you want to pass
    };
    navigate(`/search?keyword=${categoryName}`, {
      state: propsToPass,
    });
  };

  useEffect(() => {}, []);

  const categoryList = [
    {
      id: 4,
      categoryName: "발렌타인",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/1-icon.png",
    },
    {
      id: 1,
      categoryName: "조니워커",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/2-icon.png",
    },
    {
      id: 2,
      categoryName: "로얄살루트",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/3-icon.png",
    },
    {
      id: 3,
      categoryName: "카발란",

      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/4-icon.png",
    },
    {
      id: 5,
      categoryName: "발베니",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/5-icon.png",
    },
    {
      id: 6,
      categoryName: "글렌피딕",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/6-icon.png",
    },
    {
      id: 7,
      categoryName: "달모어",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/7-icon.png",
    },
    {
      id: 8,
      categoryName: "맥캘란",
      imgSrc: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/8-icon.png",
    },
  ];

  return (
    <Layout>
      <Container>
        <Underline></Underline>
        <TitleContainer> 위스키 가격비교 </TitleContainer>

        <CategoryContainer>
          {categoryList.map((item) => {
            return (
              <CategoryItem
                onClick={() => handleClickButton(item.categoryName, item.id)}
              >
                <img src={item.imgSrc}></img>
                <p>{item.categoryName}</p>
              </CategoryItem>
            );
          })}
        </CategoryContainer>
      </Container>
    </Layout>
  );
};
