
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProductItemPrice,
  ProductItemImg,
  ProductItemContainer,
  ProductItemBrand,
  ProductItemRefCode,
} from "../search/search.module";

export const ProductItem = ({ item, keyword }) => {
  const navigate = useNavigate();

  const handleItemClick = (ref_code) => {
    const encodedRefCode = encodeURIComponent(ref_code);
    const encodedKeyword = encodeURIComponent(keyword);
    const encodedBrand = encodeURIComponent(item?.data?.[0]["brand"]);
    const encodedPriceNormal = encodeURIComponent(
      item?.data?.[0]["price_normal_eng"]
    );
    navigate(
      `/product/${encodedRefCode}?searchKeyword=${encodedKeyword}&brand=${encodedBrand}&price_normal=${encodedPriceNormal}`
    );
  };

  const [imgUrl, setImgUrl] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  function removeSpecialCharsAndConvertToNumber(inputString) {
    // 정규 표현식을 사용하여 특수 문자 및 공백 제거
    const cleanedString = inputString.replace(/[^\d.]/g, "");
    // 문자열을 숫자로 변환
    const numericValue = Number(cleanedString, 10);
    return numericValue;
  }

  useEffect(() => {
    // 대표 이미지 설정
    for (const product of item["data"]) {
      if (product["img_resized_url"] != "") {
        setImgUrl(product["img_resized_url"]);
        break;
      } else if (product["img_url"] != "") {
        setImgUrl(product["img_url"]);
        break;
      }
    }

    // 가격 설정
    let minPrice = null;
    let maxPrice = null;
    for (const product of item["data"]) {
      const itemPrice = removeSpecialCharsAndConvertToNumber(
        product["price_member_eng"]
      );
      if (minPrice === null || itemPrice < minPrice) {
        minPrice = itemPrice;
      }
      if (maxPrice === null || itemPrice > maxPrice) {
        maxPrice = itemPrice;
      }
    }
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
  }, [item]);

  return (
    <ProductItemContainer>
      <div onClick={() => handleItemClick(item["ref_code"])}>
        <ProductItemImg>
          <img width="150px" src={imgUrl}></img>
        </ProductItemImg>
        <ProductItemBrand>{item["data"][0]?.["brand"]}</ProductItemBrand>
        <ProductItemBrand>{item["data"][0]?.["name"]}</ProductItemBrand>
        <ProductItemPrice>
          {" "}
          ${minPrice}~${maxPrice}
        </ProductItemPrice>
      </div>
    </ProductItemContainer>
  );
};


