import styled, { keyframes } from "styled-components";
import { ConfirmModal } from "./ConfirmModal";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px;
  // width: 360px;
`;


export const ProductTitleContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
`

export const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    width: 100%;
`

export const ProductNameContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #777;
`

export const ProductRefCode = styled.div`  width: 119px;
    margin-top: 6px; 

    height: 19px;
    width: 100%;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
`

export const ShareBtn = styled.div`  
    width: 40px;
`


const blink = keyframes`
  0%, 100% {
    opacity: 0;
  }
  15%, 85% {
    opacity: 1;
  }
`;

const blink3 = keyframes`
  0%, 60%, 80%, 100% {
    opacity: 1;
  }
  70%, 85% {
    opacity: 0;
  }
`;

export const PriceGuide = styled.div`  
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #777;

    margin-top: 14px;
    margin-bottom: 30px;


    /* 애니메이션을 적용합니다. */
    animation: ${blink3} 5s ease infinite; /* 2초 주기로 무한 반복 */

`

export const InfoArea = styled.div`  
    // margin-left: 20px;
`

export const DutyFreePriceContainer = styled.div`  
    width: 100%;
    height: 84px;
    border: solid 1px #e6e6e6;
    background-color: #fff;

    display:flex; 
    flex-direction: row;
    
    align-items: center; 
    // padding-right: 12px;
`


export const Logo = styled.div`
    width : 30%;
    margin-right: 2px;
`
export const LowestBadge = styled.div`  
    border-radius: 2px;
    background-color: #ff4b4b;  
    width: 100%;
    height: 18px;

    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #fff;
`

export const SoldoutBadge = styled.div`  
    border-radius: 2px;
    background-color: #61616175;
    width: 100%;
    height: 18px;

    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #fff;
`

export const LowestBadgeContainer = styled.div`
    width: 38px;
    margin-right: 10px;

`
export const PriceInfo = styled.div`
    // width: 100%;
    flex-grow: 1; /* 자식 요소가 나머지 공간을 모두 차지하도록 하는 중요한 부분입니다. */

    display: flex;
    flex-direction: row-reverse; /* 요소를 오른쪽에서 왼쪽으로 정렬 */  
    
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #444;
`

export const PriceInfoStrong = styled.div`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;

    margin-right : 2px;
`

export const LoadingDisplay = styled.div`
  width: 360px;
  height : 500px;

  display:flex; 
  flex-direction: column;
  
//   align-items: center; 
  justify_content: center;
`

const blink2 = keyframes`
  0%, 100% {
    opacity: 0.2;
  }
  40%, 85% {
    opacity: 0.9;
  }
`;

export const LogoContainer = styled.div`
  width : 100%;
  height: 300px;
  display:flex; 
  padding-top: 2.5rem;
  padding-left: 4rem;
  
  align-items: center; 
  justify_content: center;


  /* 애니메이션을 적용합니다. */
  animation: ${blink2} 5s ease infinite; /* 2초 주기로 무한 반복 */
`

export const ConfirmModalContainer = styled.div`
  position: fixed;
  bottom: 0; /* Ensure the bottom of the modal sticks to the bottom of the screen */
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
  width: 100%;
  z-index: 100;

  min-width: 280px; /* Adjust the max-width based on your design */
  max-width: 380px; /* Adjust the max-width based on your design */
  background: #fff; /* White background for the modal */
  padding: 30px;
  border-radius: 30px 30px 0 0; /* Top corners rounded */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 420px) {
    // min-width: 360px; /* 400px 미만인 경우 */
    width: 82vw; /* 400px 이상인 경우 */
  }
`;

export const ConfirmModalTitle = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444;
  margin-bottom: 20px;
`;

export const ConfirmModalMain = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444;
`;

export const ConfirmModalDesc = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.13px;
  text-align: center;
  color: #777;
`;

export const ModalCenterImage = styled.img`
  width: 80px; /* Adjust the width as needed */
  height: 80px; /* Adjust the width as needed */
  margin-bottom: 20px;
`;

export const CancelBtn = styled.div`
  z-index: 102;

  font-family: Pretendard;
  font-size: 16px;

  position: absolute;
  right: 0;
  top: 0;

  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
`;

export const MoveBtn2 = styled.button`
  background-color: rgb(112, 61, 255);
  color: white;
  padding: 10px 20px;
  width: 70%;
  border: none;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
`;

export const MembershipPrice = styled.span`
  color: rgb(112, 61, 255); // Change the color to your desired color
  font-weight: bold;
`;
