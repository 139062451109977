import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  height: 100%;

  @media (max-width: 400px) {
    width: 100%; /* 400px 미만인 경우 화면 너비를 100vw로 설정 */
  }

  @media (min-width: 400px) {
    min-width: 360px; /* 400px 이상인 경우 최소 너비를 360px로 설정 */
    width: 100vw; /* 400px 이상인 경우 화면 너비를 100vw로 설정 */
  }
`;

export const HomeInnerContainer = styled.div`
  background-color: white;
  max-width: 400px;
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

  @media (max-width: 420px) {
    min-width: 360px; /* 400px 미만인 경우 */
    width: 100vw; /* 400px 이상인 경우 */
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  margin-top: 2rem;
`;

export const ProductItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-right: 5rem;
`;
