import { gql } from "@apollo/client";

export const GET_DUTYFREE_COUPON_MAINPAGE = gql`
  query getDutyFreeCouponsMainPage {
    getDutyFreeCouponsMainPage {
      tag_list
      data {
        tag_name
        coupon_list {
          category
          id
          main_image_url
        }
      }
    }
  }
`;

export const GET_RECO_PRODUCT = gql`
  query getHomeRecommendData($index: Int) {
    getHomeRecommendData(index: $index) {
      id
      category
      category_data {
        ref_code
        thumbnail
        data {
          id
          name
          img_resized_url
          img_url
          duty_free_site
          brand
          price_normal_eng
          price_member_eng
        }
      }
    }
  }
`;

export const GET_AD_BANNER = gql`
  query getAdBannerList($visible: Boolean) {
    getAdBannerList(visible: $visible) {
      id
      account_id
      advertiser
      name
      image_url
      url_link
      visible
      memo
      created_at
      updated_at
    }
  }
`;

export const CREATE_LOG_MUTATION = gql`
  mutation createLog($create_log_input: CreateLogInput) {
    createLog(create_log_input: $create_log_input)
  }
`;

export const GET_WHISKY_PRODUCTS = gql`
  query {
    getWhiskyProductsForHomeSection {
      id
      img_url
      thumbnail_img_url
      name
      name_eng
      price_normal
      price_member
      tot_stock
      reg_stock
      home_category
    }
  }
`;