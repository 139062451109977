
import React, { useState, useEffect } from "react";
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { RecentKeyword } from './RecentKeyword';
import { RealTimePopular } from './RealTimePopular';
import { MostViewed } from './MostViewed';
import { MostViewed2 } from './MostViewed2';
import { Underline, SearchContainer } from '../../style/Home/RecommendSection.module';
const READ_THREE_THINGS = gql`
query readThreeThings {
  readThreeThings {
    realTime {
      rank
      name
    }
  }
}
`;

export const SearchRankPage = ({ }) => {

  const [searchRankData, setSearchRankData] = useState([])


  useEffect(() => {
    client
      .query({
        query: READ_THREE_THINGS,
      })
      .then((result) => {
        console.log(result);
        // Assuming the data you want is inside 'getCoupons' field
        setSearchRankData(result.data['readThreeThings']);
        console.log('test...', result.data['readThreeThings']);
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  }, []);


  return (
    <SearchContainer>
      <RecentKeyword ></RecentKeyword>
      <br />
      <Underline></Underline>
      <RealTimePopular data={searchRankData?.['realTime']} />
      <Underline></Underline>
      <MostViewed></MostViewed>
      <Underline></Underline>
      <MostViewed2></MostViewed2>
      {/* <h3>인기 상품</h3> */}
    </SearchContainer>
  );
};

