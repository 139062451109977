import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Layout,
  Container,
  TitleContainer,
  Underline,
} from "../../../style/Home/RecommendSection.module.js";
import { client } from "../../../client";
import { GET_WHISKY_PRODUCTS } from "../gql/queries";
import { useNavigate } from "react-router-dom";

const CategoryTags = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tag = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => (props.selected ? "#6741ff" : "#f5f5f5")};
  color: ${(props) => (props.selected ? "white" : "#1a1a1a")};
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  background-color: #f5f5f5;
`;

const ProductInfo = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ProductBrand = styled.div`
  font-size: 13px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  line-height: 1.2;
  height: 1.2em;
  margin-bottom: 2px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  margin-top: 4px;
`;

const Discount = styled.span`
  color: #ff3b3b;
  font-size: 14px;
  font-weight: bold;
`;

const Price = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const WonPrice = styled.span`
  color: #666;
  font-size: 13px;
  line-height: 1;
  margin: 0;
`;

export const WhiskySpecialSection = () => {
  const navigate = useNavigate();
  const categories = ["스카치", "버번", "일본/대만", "중국", "기타"];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [products, setProducts] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(null);

  const categoryMapping = {
    스카치: "SCOTCH",
    버번: "BOURBON",
    "일본/대만": "JAPAN",
    중국: "CHINA",
    기타: "ETC",
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await client.query({
          query: GET_WHISKY_PRODUCTS,
          fetchPolicy: "no-cache",
        });

        if (data && data.getWhiskyProductsForHomeSection) {
          setProducts(data.getWhiskyProductsForHomeSection);
        }
      } catch (error) {
        console.error("Error fetching whisky products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  const filteredProducts = products.filter(
    (product) => product.home_category === categoryMapping[selectedCategory]
  );

  const calculateDiscount = (normal, member) => {
    const normalPrice = parseFloat(normal);
    const memberPrice = parseFloat(member);
    if (normalPrice && memberPrice) {
      const discount = ((normalPrice - memberPrice) / normalPrice) * 100;
      return Math.round(discount) + "%";
    }
    return "0%";
  };

  const handleProductClick = (productId) => {
    navigate(`/whisky365/${productId}`);
  };

  return (
    <Layout>
      <Container>
        <Underline />
        <TitleContainer>면세점 위스키 특가</TitleContainer>

        <CategoryTags>
          {categories.map((category) => (
            <Tag
              key={category}
              selected={selectedCategory === category}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </Tag>
          ))}
        </CategoryTags>

        <ProductGrid>
          {filteredProducts.map((product) => (
            <ProductCard
              key={product.id}
              onClick={() => handleProductClick(product.id)}
            >
              <ProductImage
                src={product.thumbnail_img_url || product.img_url}
                alt={product.name}
              />
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductBrand>{product.name_eng}</ProductBrand>
                <PriceContainer>
                  <Discount>
                    {calculateDiscount(
                      product.price_normal,
                      product.price_member
                    )}
                  </Discount>
                  <Price>${product.price_member}</Price>
                </PriceContainer>
                <WonPrice>
                  (
                  {parseInt(
                    product.price_member * exchangeRate
                  ).toLocaleString()}
                  원)
                </WonPrice>
              </ProductInfo>
            </ProductCard>
          ))}
        </ProductGrid>
      </Container>
    </Layout>
  );
};
